import React from "react"
import queryString from "query-string"
import { getCookieItem, setCookieItem } from "../utils/common"
import { useTheme } from "../context/ThemeContext"
import { planService } from "../services"

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.setInitialState()
  }

  setInitialState() {
    const { query, theme, themeToggle } = this.props
    let affiliateId = getCookieItem("aId") || ""
    let promoCode = ""
    let pId = ""
    if (query) {
      const queriedTheme = queryString.parse(query)
      promoCode = queriedTheme.promo || ""
      affiliateId = queriedTheme.aId || affiliateId
      const pathParts = query.split("/")
      pId = pathParts[pathParts.length - 2]
      if (
        !window.location.origin.includes(pId) &&
        pId !== "pricing" &&
        pId.match(/^\d+$/)
      ) {
        // Assuming IDs are numeric
        pId = pId
      } else {
        pId = ""
      }
      if (affiliateId) {
        setCookieItem("aId", affiliateId, 60)
      }
    }
    const updatedTheme = {
      ...theme,
      affiliateId,
      pId,
      promoCode,
    }
    themeToggle.setThemeState(updatedTheme)
    this.getPricingPlans(updatedTheme, themeToggle, pId)
  }

  componentDidMount() {
    this.bindAppCues()
  }

  bindAppCues() {
    setTimeout(() => {
      if (typeof window != "undefined" && window.Appcues) {
        window.Appcues.anonymous()
        console.log("Appcues")
      }
    }, 5000)
  }

  getPricingPlans(theme, themeToggle, pId) {
    planService
      .getMainSignUpPricingPageData(theme.promoCode, theme.affiliateId, pId)
      .then((pricingPlanResponse) => {
        if (pricingPlanResponse) {
          themeToggle.setThemeState({
            ...theme,
            showLoader: false,
            pricingPlanResponse,
          })
        }
      })
  }

  render() {
    return <></>
  }
}

const Home = ({ theme, location }) => {
  const themeToggle = useTheme()
  return (
    <HomePage query={location.href} theme={theme} themeToggle={themeToggle} />
  )
}

export default Home
